import React, { useState, useEffect } from "react";
import "./portfolio.css"
import Card from "react-bootstrap/Card";
import { Spinner } from "react-bootstrap";
import { Button } from 'react-bootstrap';
import { MDBIcon, MDBBtn } from 'mdb-react-ui-kit';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Portfolio = () => {

  const portfolioBaseUrl = "https://codekaziapi.up.railway.app/portfolios";

  const [portfolios, setPortfolios] = useState();
  const [error, setError] = useState(null);

  const [cards, setCards] = useState([
    { title: 'M-Tumbler', image: 'https://res.cloudinary.com/mwikali/image/upload/v1679041808/Mtumbler_gh88qs.png', text: 'Instant Bonga Points to cash converter',live:'https://m-tumbler.com/'},
    { title: 'NextAuth.js Documentation', image:'https://res.cloudinary.com/mwikali/image/upload/v1679043378/nextauth_icotjb.webp', text: 'Documentation on how to use NextAuth.js', live:'https://www.nextauth.org/' },
    { title: 'Dr Floor', image: 'https://res.cloudinary.com/mwikali/image/upload/v1679050963/dr_x4yqnh.avif', text: 'Site that allows you to view and purchase flooring materials', live:'https://drfloor.onrender.com/'}
    ]);
  useEffect(() => {
    fetch(portfolioBaseUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        return response.json();
      })
      .then(portfolios => {
        setPortfolios(portfolios);
        setError(null);
      })
      .catch(error => {
        setPortfolios(null);
        setError(error.message);
      });
  }, []);
  if (!portfolios) {
    return <div id="portfolio" className='container relative'>
            <div className="portfolio grid grid-cols-3 ">
              <div className='flex items-center pl-1'>
                <span className="border-blue-800 border-2 w-7"></span>
                <h1 className='ml-3 text-blue-800 font-semibold text-4xl'>Our Portfolio</h1>
              </div>
            </div>
            <br></br>
            <div className='overall relative'> 
            <Swiper className='swipe' modules={[Navigation, Pagination, Scrollbar, A11y]} breakpoints={{1024: { slidesPerView: 3},768: { slidesPerView: 2},640: { slidesPerView: 1}}} speed={200} navigation pagination={{ clickable: true }} scrollbar={{ draggable: true }} >        
                  {cards.map((card) => (
                    <SwiperSlide className='flex justify-center' >
                    <Card style={{ width: '22rem' }} >
                    <Card.Img variant="top" className='card-img h-60' src={card.image} alt={card.title} />
                    <Card.Body>
                        <Card.Title className='text-yellow-500 text-center'>{card.title}</Card.Title>
                        <Card.Text className='text-center'>{card.text} </Card.Text>
                        <Card.Text className=" text-yellow-500 text-center "><a href={card.live}>View Site</a></Card.Text>
                    </Card.Body>
                    </Card>
                </SwiperSlide>
                ))}
            </Swiper>
            </div>
          </div>;
  }

  return (
    <div className="container " id="portfolio">
      <div className="portfolio grid grid-cols-3 ">
        <div className='flex items-center pl-1'>
          <span className="border-blue-800 border-2 w-7"></span>
          <h1 className='ml-3 text-blue-800 font-semibold text-4xl'>Our Portfolio</h1>
        </div>
      </div>
      <div className="row test">
        <>
          {portfolios === undefined ? (
            <Spinner animation="border" variant="light" />
          ) : (
            <>
              {portfolios === null ? (
                <p>Could not retrive portfolios.</p>
              ) : (
                <>
                  {portfolios.map(({ id, type, attributes }) => {
                    return (
                      <div className="col-md-4 mt-4" key={id}>
                        <Card className="portfolio">
                          <Card.Header>{attributes.title}</Card.Header>
                          <Card.Img
                            variant="top"
                            src={attributes['image-url']}
                            className="portfolio-img img-fluid img-thumbnail"
                          />
                          <Card.Body>

                            <Button style={{ color: '#EE9910' }} className="portfolio_button">
                              {attributes.title}
                            </Button>
                            <Card.Title style={{ color: '#000000' }}>{type}</Card.Title>
                            <Card.Text className="portfolio_description">
                              {attributes.description.split(" ").splice(0, 50).join(" ")}
                            </Card.Text>
                          </Card.Body>
                          <Card.Footer>
                            <Button style={{ fontSize: '15px', color: '#000080', border: 'none' }}
                              className="read_more-btn"
                              variant="outline-secondary"
                              size="md"
                            >
                              View More
                            </Button>

                          </Card.Footer>
                        </Card>
                      </div>
                    );
                  })}
                </>
              )}
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default Portfolio;



