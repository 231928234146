import React ,{useRef, useState} from 'react';
import "./Contact.scss";
import emailjs from '@emailjs/browser';
import { motion } from 'framer-motion';
import { contacts } from './Data';
import { FaPaperPlane } from "react-icons/fa";


const Contact = () => {
  const form = useRef();
  const [done, setDone] = useState(false)
  const [dsb, SetDisabled] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault();
    SetDisabled(true);

    emailjs
      .sendForm(
        "service_v58gjyn",
        "template_ip9zsgu",
        form.current,
        "FEEWlHNnp4x2r_RLU"
      )
      .then(
        (result) => {
          console.log(result.text);
          setDone(true);
          form.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="container" id="contact">
              <motion.div
        initial={{opacity: 0}}
        whileInView={{y: [-50, 0], opacity: 1}} 
        className="title"
      >
            <span>get in touch</span>
            <h1>Contact Us</h1>
      </motion.div>
      <div className="contact_form">
        <motion.div
          initial={{x: 0, opacity: 0}}
          whileInView={{ x: [-150,0], opacity: 1 }}
          transition={{duration: 1}}
          className='contact_left_container'>
          <h3>Get in Touch:</h3>
          <p className='contact_text'>We are the future of software</p>
          {contacts.map(contact => {
            return (
              <div className='contact_left' key={contact.id}>
                <div className="icon">
                  {contact.icon}
                </div>
                <p>{ contact.infoText}</p>
              </div>
            )
          })}
          
        </motion.div>
        <motion.div
          initial={{x: 0, opacity: 0}}
          whileInView={{ x: [150,0], opacity: 1 }}
          transition={{duration: 1}}
          className="contact_right"
        
        >
          <h3>Get In Touch</h3>
          <form ref={form} onSubmit={sendEmail}>
          <div className="row">
            <input type="text" name="user_name" className="user"  placeholder="Name"/>
            <input type="email" name="user_email" className="user" placeholder="Email"/>
            </div>

          <div className="row">
            <textarea name="message" placeholder='message'/>
          </div>

          <button type='submit'
          style={{backgroundColor: dsb ? '#000080' : '#EE9910', display: "flex", alignItems: "center", justifyContent: "center"}}
            whileHover={{ scale: 1.1 }}
            transition={{duration: 0.3}}
            className="btn1"
            disabled={dsb}
          >Send  <FaPaperPlane className="ml-1"/></button>
          <br />
            
            <span  className='POP'>{done && "Thanks for Reaching out to us, we will get back to you "}</span>
          

          
          </form>
          
  
        </motion.div>
      </div>
      
    </div>
  )
}

export default Contact
