import React from 'react';
import './App.css';
import Navbar from './components/navbar/Navbar';
import Services  from './components/services/service';
import Portfolio from './components/portfolio/Portfolio';
import Footer from './components/footer/Footer';
import Contact from './components/Contact/Contact';
import Home from './components/Home/Home';
import About from './components/About/About';
import Team from './components/Team/team';

function App() {
  return (
    <>
      <Navbar/>
      <Home/>
      <About/>
      <Services/>
      <Portfolio/>
      <Team/>
      <Contact/>
      <Footer/>
      </>
  );
}

export default App;