import React from 'react'
import './Footer.css';
import { MDBIcon, MDBBtn } from 'mdb-react-ui-kit';

function Footer() {
  const year = new Date().getFullYear();

  return (
    <>
      <div className='row1 flex justify-center' style={{ padding: '2em' }}>
        <div className='hide1 flex justify-center'>
          <MDBBtn
            floating
            className='m-1'
            style={{ backgroundColor: '#3b5998' }}
            href='https://www.facebook.com/profile.php?id=100085881925617&mibextid=ZbWKwL'
            role='button'
          >
            <MDBIcon fab icon='facebook-f' />
          </MDBBtn>
        </div>

        <div>
          <MDBBtn
            floating
            className='m-1'
            style={{ backgroundColor: '#55acee' }}
            href='https://twitter.com/code_kazi?s=20&t=z_9NMkcQW9BpzzA_sNptTQ'
            role='button'
          >
            <MDBIcon fab icon='twitter' />
          </MDBBtn>
        </div>

        <div className='hide1'>
          <MDBBtn
            floating
            className='m-1'
            style={{ backgroundColor: '#dd4b39' }}
            href='https://youtube.com/@codekazi'
            role='button'
          >
            <MDBIcon fab icon='youtube' />

          </MDBBtn>
          <MDBBtn
            floating
            className='m-1'
            style={{ backgroundColor: 'black' }}
            href='https://www.tiktok.com/@codekazi?_t=8XycfDAPir8&_r=1'
            role='button'
          >
            <MDBIcon fab icon='tiktok' />

          </MDBBtn>
        </div>
        <div>
          <MDBBtn
            floating
            className='m-1'
            style={{ backgroundColor: '#ac2bac' }}
            href='https://instagram.com/codekazi?igshid=YmMyMTA2M2Y='
            role='button'
          >
            <MDBIcon fab icon='instagram' />
          </MDBBtn>
        </div>

        <div>
          <MDBBtn
            floating
            className='m-1'
            style={{ backgroundColor: '#0082ca' }}
            href='https://www.linkedin.com/company/codekazi/'
            role='button'
          >
            <MDBIcon fab icon='linkedin-in' />
          </MDBBtn>
        </div>

        <div className='hide1'>
          <MDBBtn
            floating
            className='m-1'
            style={{ backgroundColor: '#333333' }}
            href='https://github.com/CodeKazi'
            role='button'
          >
            <MDBIcon fab icon='github' />
          </MDBBtn>
        </div>

      </div>
      <div className="copyright flex justify-center">
        <p className='mb-4'>&copy;{year} CodeKazi. All rights reserved<span></span></p>
      </div>
    </>
  );
}

export default Footer;