import React, { useState, useEffect } from "react";
import Card from 'react-bootstrap/Card';
import Spinner from "react-bootstrap/Spinner";
import './services.css'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { contacts } from "../Contact/Data";

const Services = () => {

  const servicesBaseUrl = "https://codekaziapi.up.railway.app/services";
  const [service, setService] = useState();
  const [error, setError] = useState(null);


  const [cards, setCards] = useState([
    { title: 'Product Design(UI/UX)', image: 'https://images.unsplash.com/photo-1621111848501-8d3634f82336?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1265&q=80', text: 'This is the process of creating new products or improving existing products to meet user needs and solve problems. It involves a combination of user research, ideation, prototyping, and testing to create products that are functional, usable, and aesthetically pleasing.'},
    { title: 'Product Development', image: 'https://images.unsplash.com/photo-1593720213428-28a5b9e94613?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80', text: 'Once the design is complete, the product is prototyped and tested to ensure that it meets customer needs and is technically feasible. This may involve user testing, functionality testing, and quality assurance. The product is then refined based on the feedback received.'},
    { title: 'Digital Marketing', image: 'https://images.unsplash.com/photo-1557838923-2985c318be48?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1331&q=80', text: 'The process of promoting products or services using digital technologies, such as the internet, social media, search engines, mobile devices, and other digital channels. The goal of digital marketing is to reach and engage with potential customers in a cost-effective and measurable way.'},
    { title: 'Hiring Designers and Developers', image: 'https://images.unsplash.com/photo-1521791136064-7986c2920216?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fGhpcmluZ3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60', text: 'Dedicated Development Center model offered to those customers who are looking for a dedicated resource/s to work on their projects onsite/offsite. The resource will work as per customers’ requirements set for a stipulated time frame decided prior with terms and conditions favorable to both ends.'},
    { title: 'Technical Writing', image: 'https://res.cloudinary.com/mwikali/image/upload/v1679051231/techwriting_crmxqx.jpg',text: 'We help you communicate complex technical information in a clear and concise manner. We use a variety of tools and techniques to create technical documents, such as user manuals, technical reports, whitepapers, and online help systems.'}
    ]);

  useEffect(() => {
    fetch(servicesBaseUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        return response.json();
      })
      .then(service => {
        setService(service);
        setError(null);
      })
      .catch(error => {
        setService(null);
        setError(error.message);
      });
  }, []);
  if (!service) {
    return <div id="services" className='container relative service-c'>
              <div className="service grid grid-cols-3 ">
                <div className='row2 flex items-center pl-1'>
                  <span className="border-blue-800 border-2 w-7"></span>
                  <h1 className='ml-3 text-blue-800 font-semibold text-4xl'>What We Do</h1>
                </div>
              </div>
              <br></br>
              <div className='overall relative'> 
              <Swiper className='swipe' modules={[Navigation, Pagination, Scrollbar, A11y]} breakpoints={{1024: { slidesPerView: 3},768: { slidesPerView: 2},640: { slidesPerView: 1}}} speed={200} navigation pagination={{ clickable: true }} scrollbar={{ draggable: true }} >        
                    {cards.map((card) => (
                      <SwiperSlide className='flex justify-center' >
                      <Card style={{ width: '22rem' }} >
                      <Card.Img variant="top" className='card-img h-60' src={card.image} alt={card.title} />
                      <Card.Body>
                          <Card.Title className='text-yellow-500 text-center'>{card.title}</Card.Title>
                          <Card.Text className='text-center'>{card.text} </Card.Text>
                      </Card.Body>
                      </Card>
                  </SwiperSlide>
                  ))}
              </Swiper>
              </div>
          </div>;
  }

  return (
    <div id="services" className="container">
      <div className='flex items-center pl-1'>
        <span className="border-blue-800 border-2 w-9"></span>
        <h2 className='ml-3 text-blue-800 font-semibold text-4xl '>What We Do</h2>
      </div>

      <div className="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5">
          {service === undefined ? (<Spinner animation="border" variant="light" />) : (<>{service === null ? (<p>Could not retrive the services.</p>) : (<>
            {service.map(({ id, type, attributes }) => {
              return (

                <div class="rounded overflow-hidden shadow-lg" key={id}>
                  <img class="w-full h-48" src={attributes['image-url']} alt={attributes.title}/>
                  <div class="px-6 py-4">
                    <div class="font-bold text-xl mb-2">{attributes.title}</div>
                    <p class="text-gray-700 text-base">
                      {attributes.description.split(" ").splice(0, 20).join(" ")}
                    </p>
                  </div>
                  <div class="px-6 pt-4 pb-2">
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#{attributes.title}</span>
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#Software</span>
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#Development</span>
                  </div>
                </div>

              )
            })}</>)}</>)}
      </div>
    </div>
  )
}
export default Services;