import React from 'react';
import './About.css'


function About() {
    return (
        <div id="about" className='container about-c'>
            <div className='overall grid grid-cols-2'>
                <div className='col-span-1 image-about'>
                    <img className='about-image' src="https://lalitpransukhka.com/wp-content/uploads/2022/05/flat-design-web-developers_23-2148817994.webp" alt="" />
                </div>
                <div className='col-span-1 pl-20 about-content'>
                    <div className='flex items-center pl-1'>
                        <span className="border-yellow-600 border-2 w-9"></span>
                        <h2 className='ml-3 text-yellow-600 font-semibold text-4xl'>Why Code Kazi</h2>
                    </div>
                    <br></br>
                    <h1 className='text-xl'>Why Work With Us ?</h1>
                    <br></br>
                    <p className='pr-80 p-content '>We create an integrated mobile and web presence that generates ROI for you. We get more traffic to your website and mobile applications</p>
                    <br></br>
                    <div className='flex items-center pb-3'>
                        <div className="circle-tick flex items-center justify-center">
                            <span className="tick">✔</span>
                        </div>
                        <span className='ml-2'>Trustworthy</span>
                    </div>
                    <div className='flex items-center pb-3'>
                        <div className="circle-tick flex items-center justify-center">
                            <span className="tick">✔</span>
                        </div>
                        <span className='ml-2'>Guaranteed Results</span>
                    </div>
                    <div className='flex items-center pb-12'>
                        <div className="circle-tick flex items-center justify-center">
                            <span className="tick">✔</span>
                        </div>
                        <span className='ml-2 '>Fast and Reliable</span>
                    </div>
                    <a className=" text-yellow-500 hover:text-blue-900 font-semibold text-2xl underline underline-offset-8" href="#about">Get In Touch -&gt;</a>
                </div>
            </div>
        </div>

    );
}

export default About;