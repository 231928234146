import { useState} from 'react';
import Card from 'react-bootstrap/Card';
import { MDBIcon, MDBBtn } from 'mdb-react-ui-kit';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import './team.css'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';



function Team() {
    const [cards, setCards] = useState([
        { title: 'Charles Muthii', image: 'https://res.cloudinary.com/mwikali/image/upload/v1677346094/pp_bkc9x1.jpg', text: 'An Application Security Professional focusing on both Offensive and Defensive Security.He has over 5+ years of industry experience conducting Professional Vulnerability Assessments, Penetration Tests, Code reviews, Exploit Development, Vulnerability Research, and bug patching on mobile applications as well as delivering training on Ethical Hacking methodologies and concepts.He is also a Passionate Software Developer specializing in Android, Web, and API development with a passion for coding in general, learning, and extreme research.',linkedIn:'https://www.linkedin.com/in/chal13w1zz/',twitter:'https://twitter.com/Chal13W1zz' },
        { title: 'Diana Mongina', image: 'https://res.cloudinary.com/mwikali/image/upload/v1677351212/Diana_mzkfg4.jpg', text: 'An experienced software engineer with 2+ years of experience and a passion for developing innovative programs that expedite the efficiency and effectiveness of organizational success. Well-versed in technology and writing code to create systems that are reliable and user-friendly.A skilled leader who has the proven ability to motivate, educate, and manage a team of professionals to build software programs and effectively track changes. Confident communicator, strategic thinker and an innovative creator',linkedIn:'https://www.linkedin.com/in/diana-mongina-74196a1a2',},
        { title: 'Edwike Nyauncho', image: 'https://res.cloudinary.com/mwikali/image/upload/v1677341737/Edwike_yx7uwr.jpg', text: 'A Software developer with 2+ years of experience with strong skills in programming (Python Django, JavaScript, Angular & React)and introductory physics.She has her interest in IT support and is determined to grow a career in software development, setting high standards for her profession and innovation in implementation and executing various projects. She looks forward to working in a dynamic and challenging environment that will grow her skills in analytical instrumentation, computer science, and communication skills.',linkedIn:'https://www.linkedin.com/in/edwike-nyauncho-0b260b14b/', twitter:'https://twitter.com/nyauncho_edwike?t=3sfqwGza17gXXSlDBFAcWw&s=08'},
        { title: 'Ezekiel Kibiego', image:'https://res.cloudinary.com/mwikali/image/upload/v1677342542/Ezekiel_eyibrw.jpg', text: 'A seasoned software developer with extensive experience in Java, Python, JavaScript, and DevOps.He has a strong track record of developing robust and scalable applications that meet the evolving needs of clients in various industries.He is also well-versed in API gateway technologies and has a deep understanding of WSO2.',linkedIn:'https://www.linkedin.com/in/ezekielkibiego', twitter:'https://twitter.com/KibiegoEzekiel?s=09'},
        { title: 'Joan Kirui', image:'https://res.cloudinary.com/mwikali/image/upload/v1679037009/joan_hzhonr.jpg', text: 'A Python software developer with 2+ years of experience . Well versed with React and Python frameworks . Also proficient at Mathematics,strong time management,strong desire to learn new skills and ability to work in a team.',linkedIn:'https://www.linkedin.com/in/joan-kirui-66208518a/', twitter:'https://twitter.com/kirui_lala'},
        { title: 'Maryann Mwikali', image: 'https://res.cloudinary.com/mwikali/image/upload/v1677345902/1636007793089_rnbvdz.jpg', text: 'A Software Engineer with 3+ years of experience,passionate about software development and how to expand her knowledge in its various fields. Over the 3 years she has been designing and building personal and work projects to production as well as maintaining and making updates.She has developed skills that are important including research, design, developing, debugging ,testing and deployment. Overall, she has consistently demonstrated critical thinking, problem-solving and teamwork abilities',linkedIn:'https://www.linkedin.com/in/maryann-mwikali/',twitter:'https://twitter.com/_vodca_' },
        { title: 'Rhona Joy Koome', image: 'https://res.cloudinary.com/mwikali/image/upload/v1677342141/Rhonajoy_ox6uag.jpg', text: 'A full-stack developer with skills and knowledge to design, develop and deploy dynamic Web applications that help solve various societal problems.Her expertise lies in both front-end and back-end development. Proficient in programming languages such as Javascript, Python, Ruby.She is a problem solver and a life-long learner who knows how to adapt to new technologies and stay up to date with latest industry trends.', linkedIn:'http://linkedin.com/in/koome-rhonajoy', twitter:'https://twitter.com/RhonaJoy_?t=p92ipRZr8gsIi_eqq6czmQ&s=09' },
        { title: 'Sheldon Okware', image: 'https://res.cloudinary.com/mwikali/image/upload/v1677435644/Sheldon_b4kxrd.jpg', text:'A mobile developer with over two years experience and fundamental knowledge of software design and testing.He has a strong understanding of both ios and android platforms and has worked on a range of projects.He is well versed in a variety of programming languages including java, kotlin and react native.He has strong problem-solving skills and attention to detail that has proven record of developing high quality mobile applications that not only  meet clients needs but exceeds their expectations.He is also skilled in user experience design creating intuitive and visually appealing interfaces.',linkedIn:'https://linkedin.com/in/sheldon-okware',twitter:'https://twitter.com/_sheldonO' },
        { title: 'Simon Kairu', image: 'https://res.cloudinary.com/mwikali/image/upload/v1677434238/simon_s9abwa.jpg', text:'A digital marketer with 2+ years of experience with expertise in creating, managing and promoting online businesses of all sizes. I am able to provide a full suite of services to my clients, from designing and building websites to creating digital marketing strategies and providing IT support. My passion for technology and my dedication to providing excellent customer service are what sets me apart from others in the industry. I always strive to keep up with the latest trends and technologies to ensure my clients businesses are always ahead of the curve',linkedIn:'https://www.linkedin.com/in/simon-kairu-0073aa1b5',twitter:'https://twitter.com/MapsyiTech?t=yVo8m5CWGBZMlJTrASRDOg&s=09' },
        { title: 'Timothy Mugendi', image: 'https://res.cloudinary.com/mwikali/image/upload/v1677435085/Timo_j3lzqd.jpg', text: 'Passionate about solving complex problems and creating innovative solutions that help improve peoples lives.With a Bachelors degree in Mathematics and experience in programming languages such as Python and Javascript, frameworks such as Django and Flask and Reactjs, he is confident in writing clean, efficient and scalable code.He has had the opportunity to work on various projects, from developing web applications to integrations that require Mathematics concepts.',linkedIn:'https://www.linkedin.com/in/timothy-mugendi-489ab4122',twitter:'https://twitter.com/Timoh971' },


        // { title: 'Card 3', image: {me}, text: 'Some text for card 3' },
        // { title: 'Card 4', image: , text: 'Some text for card 3' },

        // ...
        ]);

    
  return (
    <div id="team" className='container relative team-c'>
        <h1 className='text-center text-yellow-500 text-xl pb-2'>Our Team</h1>
        <h2 className='text-center text-4xl font-semibold pb-4'>The Amazing Team Behind CodeKazi</h2>
        <div className='overall relative'> 
        <Swiper className='swipe' modules={[Navigation, Pagination, Scrollbar, A11y]} breakpoints={{1024: { slidesPerView: 3},768: { slidesPerView: 2},640: { slidesPerView: 1}}} speed={200} navigation pagination={{ clickable: true }} scrollbar={{ draggable: true }} >        
               {cards.map((card) => (
                <SwiperSlide className='flex justify-center' >
                <Card style={{ width: '22rem' }} >
                <Card.Img variant="top" className='card-img h-80 p-4 rounded-full ' src={card.image} alt={card.title} />
                <Card.Body>
                    <Card.Title className='text-yellow-500 text-center'>{card.title}</Card.Title>
                    <Card.Text className='text-start'>{card.text} </Card.Text>
                    <div className='flex justify-center'>
                        <div className='flex justify-center p-2'>
                            <Card.Link>
                                <MDBBtn floating className='m-1' href={card.linkedIn} role='button'>
                                    <MDBIcon fab icon='linkedin-in' />
                                </MDBBtn>
                            </Card.Link>
                            <Card.Link>
                                <MDBBtn floating className='m-1' href={card.twitter} role='button'>
                                    <MDBIcon fab icon='twitter' />
                                </MDBBtn>
                            </Card.Link>
                        </div>
                    </div>
                </Card.Body>
                </Card>
            </SwiperSlide>
            ))}
        </Swiper>
        </div>
    </div>
    
  );
}

export default Team;

