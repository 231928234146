import React from "react"
import "./home.css"
import developer from '../../assets/coding-removebg-preview.png'

export default function Home() {
  return (

    <div id="home" className="container home pt-32">
      <div className="overall grid grid-cols-2">
        <div className="col-span-1 pl-1 pt-20 home-content">
          <h1 className="first-header text-yellow-500 font-semibold text-4xl px-12">The Leading Mobile/Web Design & Development Company</h1> 
          <p className="leading-7 py-4 px-10 ">We create an integrated mobile and web presence that generates ROI for you. We get more traffic to your website and mobile applications by expanding your online presence and then effectively converting that traffic into your new leads and sales.</p>
          <a className=" text-blue-900 hover:text-yellow-500 font-semibold text-2xl underline underline-offset-8 p-10" href="#about">Get Started -&gt;</a>
        </div>
        <div className="col-span-1 image-home">
          <img className="home-image" src={developer} alt="developer"/>
        </div>
      </div>
    </div>
  )

}

